import { graphql } from 'gatsby';
import React from 'react';
import { Intro } from '../components/Intro';
import { Layout } from '../components/Layout';
import { NewsletterForm } from '../components/NewsletterForm';
import { SEO } from '../components/SEO';

const ArticleTemplate: React.FC<any> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <Intro>
        <h2>{frontmatter.title}</h2>
        <h3>{frontmatter.date}</h3>
      </Intro>

      <div className="container">
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>

      <NewsletterForm />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        rawDate: date(formatString: "YYYY-MM-DD")
        slug
        title
      }
    }
  }
`;

export default ArticleTemplate;
